import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "slottimeselection",
    "zonesselection",
    "slotVehicleId",
    "slotDaysInput",
    "premiumInstructorId",
    "errorMessageContainer",
    "errorMessage",
    "loader",
    "formButtons",
    "successContainer",
  ];

  apiErrors = [];
  changedElements = [];

  connect() {}

  validateForm(event) {
    const errors = [];

    this.changedElements.push(event.currentTarget);

    if (this.changedElements.includes(this.slottimeselectionTarget)) {
      try {
        const slotTimesValue = JSON.parse(this.slottimeselectionTarget.value);
        if (slotTimesValue.length === 0) {
          errors.push("Please select at least one slot time");
        }
      } catch {}
    }

    if (this.changedElements.includes(this.zonesselectionTarget)) {
      try {
        const zonesValue = JSON.parse(this.zonesselectionTarget.value);
        if (zonesValue.length === 0) {
          errors.push("Please select at least one zone");
        }
      } catch {}
    }

    if (this.changedElements.includes(this.slotVehicleIdTarget)) {
      try {
        const slotVehicleIdValue = this.slotVehicleIdTarget.value;
        if (slotVehicleIdValue === "") {
          errors.push("Please select a vehicle");
        }
      } catch {}
    }

    if (this.changedElements.includes(this.slotDaysInputTarget)) {
      try {
        const slotDaysValue = JSON.parse(this.slotDaysInputTarget.value);
        if (slotDaysValue.length === 0) {
          errors.push("Please select at least one day");
        }
      } catch {}
    }

    errors.push(...this.apiErrors);

    if (errors.length > 0) {
      this.errorMessageContainerTarget.classList.remove("u-hidden");
    } else {
      this.errorMessageContainerTarget.classList.add("u-hidden");
    }

    this.errorMessageTarget.innerHTML = "";
    errors.forEach((error) => {
      // Create a new p element
      const p = document.createElement("p");
      p.innerHTML = error;
      this.errorMessageTarget.appendChild(p);
    });

    return errors;
  }

  closeQuickLook(event) {
    event.preventDefault();
    const quickViewElement = document.querySelector(
      "#school-shifts-quick-look"
    );
    quickViewElement.classList.add("u-hidden");
  }

  success() {
    this.loaderTarget.classList.add("u-hidden");
    this.formButtonsTarget.classList.add("u-hidden");
    this.successContainerTarget.classList.remove("u-hidden");
  }

  beginLoading() {
    this.loaderTarget.classList.remove("u-hidden");
    this.formButtonsTarget.classList.add("u-hidden");
  }

  endLoading() {
    this.loaderTarget.classList.add("u-hidden");
    this.formButtonsTarget.classList.remove("u-hidden");
  }

  async savePattern(event) {
    this.beginLoading();
    this.apiErrors = [];

    // Add all the elements to the changed elements array
    this.changedElements.push(
      this.slottimeselectionTarget,
      this.zonesselectionTarget,
      this.slotVehicleIdTarget,
      this.slotDaysInputTarget
    );
    const validationErrors = this.validateForm(event);

    if (validationErrors.length > 0) {
      this.endLoading();
      return;
    }

    const authenticity_token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;
    const premium_instructor_id = this.premiumInstructorIdTarget.value;
    const slottimeselection = this.slottimeselectionTarget.value;
    const zonesselection = this.zonesselectionTarget.value;
    const slot_vehicle_id = this.slotVehicleIdTarget.value;
    const slot_days_input = this.slotDaysInputTarget.value;
    const editId = event.currentTarget.dataset.editId;

    const data = {
      premium_instructor_id,
      slottimeselection,
      zonesselection,
      slot_vehicle_id,
      slot_days_input,
      authenticity_token,
      editId,
    };

    try {
      const result = await fetch("/schools/setup/shifts/flexible/new", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.ok) {
        this.endLoading();
        this.success();
      } else {
        this.endLoading();
        this.apiErrors.push(
          "There was an error saving, please try again later"
        );
        this.validateForm(event);
      }
    } catch (error) {
      this.endLoading();
      this.apiErrors.push("There was an error saving, please try again later");
      this.validateForm(event);
    }
  }

  async removePattern(event) {
    this.beginLoading();
    this.apiErrors = [];
    this.validateForm(event);

    const authenticity_token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;
    const idToDelete = event.currentTarget.dataset.deleteId;
    const premium_instructor_id = this.premiumInstructorIdTarget.value;

    const data = {
      id: idToDelete,
      authenticity_token,
      premium_instructor_id,
    };

    try {
      const result = await fetch("/schools/setup/shifts/flexible/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.ok) {
        this.endLoading();
        this.success();
      } else {
        this.endLoading();
        this.apiErrors.push(
          "There was an error removing, please try again later"
        );
        this.validateForm(event);
      }
    } catch (error) {
      this.endLoading();
      this.apiErrors.push(
        "There was an error removing, please try again later"
      );
      this.validateForm(event);
    }
  }
}
