import { Controller } from "@hotwired/stimulus";
import * as Luxon from "luxon";

export default class extends Controller {
  static targets = ["day", "cell", "cellNumber", "shortMonth"];

  year = 0;
  month = 0;
  timezone = "UTC";
  events = [];
  selectedDays = [];
  selectedDaysInputSelector = "";
  hasBeenInteractedWith = false;

  connect() {
    setTimeout(() => {
      this.year = Number(this.data.get("year"));
      this.month = Number(this.data.get("month"));
      this.timezone = this.data.get("timezone");
      this.events = JSON.parse(this.data.get("events"));
      this.selectedDays = JSON.parse(this.data.get("selectedDays"));
      this.selectedDaysInputSelector = this.data.get(
        "selectedDaysInputSelector"
      );
      this.redraw();
    }, 0);
  }

  nextMonth() {
    this.month++;
    if (this.month > 12) {
      this.month = 1;
      this.year++;
    }
    this.redraw();
  }

  previousMonth() {
    this.month--;
    if (this.month < 1) {
      this.month = 12;
      this.year--;
    }
    this.redraw();
  }

  selectDay(event) {
    const isoDate = event.currentTarget.dataset.date;
    const isSelected = this.selectedDays.includes(isoDate);
    if (isSelected) {
      this.selectedDays = this.selectedDays.filter((day) => day !== isoDate);
    } else {
      this.selectedDays.push(isoDate);
    }
    this.hasBeenInteractedWith = true;
    this.redraw();
  }

  redraw() {
    let day = Luxon.DateTime.fromObject(
      {
        year: this.year,
        month: this.month,
        day: 1,
      },
      {
        zone: this.timezone,
      }
    );

    this.shortMonthTargets.forEach((shortMonth) => {
      shortMonth.innerHTML = `${day.monthShort} ${day.year}`;
    });

    while (day.weekdayLong != "Sunday") {
      day = day.minus({ days: 1 });
    }

    let hasGoneNextMonth = false;

    for (let row = 0; row <= 5; row++) {
      for (let col = 0; col <= 6; col++) {
        // Retrieve the elements
        const cellNumber = this.cellNumberTargets.find((cell) => {
          return cell.dataset.row == row && cell.dataset.col == col;
        });
        const cell = this.cellTargets.find((cell) => {
          return cell.dataset.row == row && cell.dataset.col == col;
        });

        // Edit the cell data
        cell.dataset.date = day.toISODate();

        // Edit the cell content
        cellNumber.innerHTML = day.day;

        // Muted the cell if it's not in the current month
        if (`${day.month}` === `${this.month}`) {
          cellNumber.classList.remove("u-textMuteder");
        } else {
          cellNumber.classList.add("u-textMuteder");
        }

        // Hide the cell if we are into the next month and next week
        if (hasGoneNextMonth) {
          cell.classList.add("u-hidden");
        } else {
          cell.classList.remove("u-hidden");
        }

        // Make blue if any events occur within the day
        const startOfDay = day.startOf("day");
        const endOfDay = day.endOf("day");
        const events = this.events.filter((event) => {
          const anyOverlap = !(
            endOfDay < Luxon.DateTime.fromISO(event.start) ||
            startOfDay > Luxon.DateTime.fromISO(event.end)
          );
          return anyOverlap;
        });
        if (events.length > 0) {
          cell.classList.add("occupied");
        } else {
          cell.classList.remove("occupied");
        }

        // Make background blue if the day is selected
        const selectedDays = this.selectedDays.filter((selectedDay) => {
          const luxon = Luxon.DateTime.fromISO(selectedDay, {
            zone: this.timezone,
          });
          return luxon.hasSame(day, "day");
        });
        if (selectedDays.length > 0) {
          cell.classList.add("u-bgBrandLighter");
        } else {
          cell.classList.remove("u-bgBrandLighter");
        }

        day = day.plus({ days: 1 });

        // If it's a sunday and the next month has already started, we are into the next month
        if (
          day.weekdayLong == "Sunday" &&
          (day.month === this.month + 1 ||
            (day.month === 1 && this.month === 12))
        ) {
          hasGoneNextMonth = true;
        }
      }
    }

    // Update the selected days input
    const selectedDaysInput = document.querySelector(
      this.selectedDaysInputSelector
    );
    if (selectedDaysInput) {
      selectedDaysInput.value = JSON.stringify(this.selectedDays);
      if (this.hasBeenInteractedWith) {
        selectedDaysInput.dispatchEvent(new Event("input", { bubbles: true }));
        selectedDaysInput.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }
  }
}
