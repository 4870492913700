import { Controller } from "@hotwired/stimulus";

// TODO: Write out some docs for this

export default class extends Controller {
  static targets = ["entityCheckbox", "entityDayCheckbox", "entityDays"];

  entities = [];

  connect() {
    setTimeout(() => {
      this.entities = JSON.parse(this.data.get("entities"));
      this.updateBoxesAccordingToEntities();
      this.boxesChanged();
      this.updateInput();
    });
  }

  updateBoxesAccordingToEntities() {
    this.entityDayCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });

    this.entities.forEach((entity) => {
      entity.days.forEach((day) => {
        const dayCheckbox = this.entityDayCheckboxTargets.find((checkbox) => {
          return (
            checkbox.getAttribute("data-entity-id") === `${entity.id}` &&
            checkbox.getAttribute("data-day") === `${day}`
          );
        });
        if (dayCheckbox) {
          dayCheckbox.checked = true;
        }
      });
    });
  }

  boxesChanged() {
    const result = [];
    const entitiesToTickEachDay = [];
    const entitiesToShow = [];

    this.entityCheckboxTargets.forEach((checkbox) => {
      const id = checkbox.getAttribute("data-entity-id");
      const label = checkbox.getAttribute("data-entity-label");
      const assigned = checkbox.checked;
      const entityTickedBefore =
        this.entities.find((entity) => `${entity.id}` === `${id}`) &&
        this.entities.find((entity) => `${entity.id}` === `${id}`).assigned;
      const entityNewlyTicked = assigned && !entityTickedBefore;

      if (entityNewlyTicked) {
        entitiesToTickEachDay.push(id);
      }
      if (assigned) {
        entitiesToShow.push(id);
      }
      result.push({ label, id, assigned, days: [] });
    });

    this.entityDaysTargets.forEach((entityDays) => {
      if (entitiesToShow.includes(entityDays.getAttribute("data-entity-id"))) {
        entityDays.classList.remove("hidden");
      } else {
        entityDays.classList.add("hidden");
      }
    });

    this.entityDayCheckboxTargets.forEach((checkbox) => {
      const id = checkbox.getAttribute("data-entity-id");
      const day = checkbox.getAttribute("data-day");

      if (entitiesToTickEachDay.includes(id)) {
        checkbox.checked = true;
      }

      const assigned = checkbox.checked;
      const entity = result.find((entity) => `${entity.id}` === `${id}`);
      if (entity && assigned) {
        entity.days.push(day);
      }
    });

    this.data.set("entities", JSON.stringify(result));
    this.entities = result;
    this.updateInput();
  }

  updateInput() {
    const inputSelector = this.data.get("inputSelector");
    if (inputSelector) {
      const input = document.querySelector(inputSelector);
      if (input) {
        input.value = JSON.stringify(this.entities);
      }
    }
  }
}
