// referral_bookings_decline_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "declineButton",
    "error",
    "successMessage",
    "mainForm",
    "validationError",
    "proposeNewDate",
    "updateLocation",
    "updateAvailability",
    "newFeatureTest",
    "reasonInput",
    "hiddenRadio",
    "charCount",
    "doNotIncludeMobile",
    "offerLaterDateWarning",
    "offerDifferentLocationPanel",
    "offerLaterDateMonthWarning",
    "enterSuggestedLocationWarning",
    "offerDifferentLocationPanelWarning",
    "suggestPickupLocation",
    "instructorSuggestedLocation"
  ]

  connect() {

  }

  // Handle character count for custom reason


  // Main decline action
  decline(event) {
    event.preventDefault()

    // Disable the button
    this.declineButtonTarget.disabled = true

    // Get booking ID from URL
    let bookingIdToDecline = 0
    const urlArray = window.location.href.split("/")
    for (let i = 0; i < urlArray.length; i++) {
      if (urlArray[i] === 'bookings') {
        bookingIdToDecline = urlArray[i+1]
        break
      }
    }

    // If we couldn't find it in the URL, try to get it from the hidden field
    if (bookingIdToDecline === 0) {
      const bookingIdElement = document.getElementById('booking_id')
      if (bookingIdElement) {
        bookingIdToDecline = bookingIdElement.textContent.trim()
      }
    }

    // Get selected reason
    const selectedRadio = this.element.querySelector(".edit_booking input[type='radio']:checked")
    let reason

    // Check if we're using the custom reason input
    if (selectedRadio && selectedRadio.id === 'hidden_radio') {
      reason = this.reasonInputTarget.value
    } else if (selectedRadio) {
      reason = selectedRadio.value
    }

    // Validate reason length
    if (reason === undefined || reason.length < 10 || reason.length > 140) {
      if (this.hasErrorTarget) {
        this.errorTarget.hidden = false
      }
      if (this.hasValidationErrorTarget) {
        this.validationErrorTarget.hidden = false
      }
      window.scrollTo(0, 0)
      this.declineButtonTarget.disabled = false
      return
    }

    // Check for mobile or email in the reason
    if (/(\b\d{10,11}\b|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/.test(reason)) {
      if (this.hasDoNotIncludeMobileTarget) {
        this.doNotIncludeMobileTarget.hidden = false
      }
      this.declineButtonTarget.disabled = false
      return
    }

    // Get magic link parameter (if any)
    const magicLink = this.getUrlParameter('magic_link')

    // Get booking token if available
    const bookingTokenElement = document.getElementById('booking-token-value')
    let bookingToken = null
    if (bookingTokenElement) {
      bookingToken = bookingTokenElement.textContent.trim()
    }

    // Prepare data to send
    let dataToSend = { reason: reason }

    if (magicLink !== null) {
      dataToSend.magic_link = magicLink
    }

    if (bookingToken) {
      dataToSend.booking_token = bookingToken
    }

    // Make AJAX request
    fetch(`/bookings/${bookingIdToDecline}/decline`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
      },
      body: JSON.stringify(dataToSend)
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then(data => {
          // Handle success
          if (this.hasSuccessMessageTarget) {
            this.successMessageTarget.hidden = false
          }

          if (this.hasNewFeatureTestTarget) {
            this.newFeatureTestTarget.hidden = false
          }

          if (this.hasMainFormTarget) {
            this.mainFormTarget.hidden = true
          }

          if (this.hasErrorTarget) {
            this.errorTarget.hidden = true
          }

          if (this.hasProposeNewDateTarget) {
            this.proposeNewDateTarget.hidden = true
          }

          window.scrollTo(0, 0)

          // Handle specific decline reasons
          if (data.decline_reason && data.decline_reason.includes('your area') && this.hasUpdateLocationTarget) {
            this.updateLocationTarget.hidden = false
            if (this.hasNewFeatureTestTarget) {
              this.newFeatureTestTarget.hidden = true
            }
          }

          if (data.decline_reason && data.decline_reason.includes('too busy') && this.hasUpdateAvailabilityTarget) {
            this.updateAvailabilityTarget.hidden = false
            if (this.hasNewFeatureTestTarget) {
              this.newFeatureTestTarget.hidden = true
            }
          }
        })
        .catch(error => {
          // Handle error
          if (this.hasErrorTarget) {
            this.errorTarget.hidden = false
          }
          this.declineButtonTarget.disabled = false
          window.scrollTo(0, 0)
        })
  }

  // Helper function to get URL parameters
  getUrlParameter(name) {
    const url = window.location.search.substring(1)
    const urlParams = url.split('&')

    for (let i = 0; i < urlParams.length; i++) {
      const parameter = urlParams[i].split('=')
      if (parameter[0] === name) {
        return parameter[1] === undefined ? null : decodeURIComponent(parameter[1])
      }
    }
    return null
  }
}