import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["canvas"]
    static values = { data: Array }

    connect() {
        if (this.hasDataValue && this.hasCanvasTarget) {
            this.initChart()
        }
    }

    initChart() {
        const data = this.dataValue

        // Extract dates and amounts
        const labels = data.map(item => item.date)
        const amountData = data.map(item => (item.total_amount / 100).toFixed(2)) // Convert pence to pounds/dollars

        // Create chart
        new Chart(this.canvasTarget, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Total Amount (£)',
                    data: amountData,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 2,
                    tension: 0.1,
                    fill: true,
                    pointRadius: 3
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            // Add pound/dollar sign to y-axis labels
                            callback: function(value) {
                                return '£' + value;
                            }
                        }
                    },
                    x: {
                        ticks: {
                            maxTicksLimit: 10 // Limit number of x-axis labels for better readability
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                return '£' + context.raw;
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: 'top'
                    }
                }
            }
        })
    }
}