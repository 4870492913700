import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

  }

  async markAsReadyForImport(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const studentId = button.dataset.studentId;

    // Disable the button to prevent multiple clicks
    button.disabled = true;
    button.classList.add('Button--disabled');
    button.textContent = 'Processing...';

    try {
      const url = `/data-importer/step-3/student/${studentId}/mark-ready`;
      const response = await this.performFetch(url, "POST", {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
          'Accept': 'application/json' // Add this line to request JSON response
        }
      });

      if (response.ok) {
        // Success - close the quick look
        this.closeQuickLook(event);

        // Optionally refresh the student row
        window.location.reload(); // Simplest approach: just refresh the page
      } else {
        // If the response is not OK, show an error message
        const data = await response.json();
        alert(data.error || 'Failed to mark record as ready for import.');
      }
    } catch (error) {
      console.error('Error marking record as ready:', error);
      alert('An error occurred. Please try again.');
    } finally {
      // Re-enable the button in case the quick look doesn't close
      button.disabled = false;
      button.classList.remove('Button--disabled');
      button.textContent = 'Mark as Ready for Import';
    }
  }


  async showQuickLook(event) {
    event.preventDefault();
    const quickViewElement = document.querySelector("#school-resources-quick-look");
    quickViewElement.classList.remove("u-hidden");
    // //
    // // const type = event.currentTarget.dataset.type;
    // // const id = event.currentTarget.dataset.id || "";
    // //
    const id = event.currentTarget.dataset.id || "";
    const url = `/data-importer/step-3/student/${id}/quick-look`
    const response = await this.performFetch(url, "GET");
    // //
    // // const response = await this.performFetch(url, "GET");
    //
    if (response.ok) {

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const turboFrameElement = quickViewElement;

      if (turboFrameElement && template) {
        turboFrameElement.innerHTML = "";
        turboFrameElement.appendChild(template.content.cloneNode(true));
      }
    } else {
      alert('we did not get it')
    }
  }

  async performFetch(url, method, body = null) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const headers = {
      "X-CSRF-Token": csrfToken,
      "Accept": "text/vnd.turbo-stream.html"
    };

    const options = {
      method: method,
      headers: headers
    };

    if (body) {
      options.body = body;
    }

    try {
      return await fetch(url, options);
    } catch (error) {
      console.error("Error performing fetch: ", error);
      return { error: "Network error, please check your connection and try again." };
    }
  }

  closeQuickLook(event) {
    event.preventDefault();
    const quickViewElement = document.querySelector("#school-resources-quick-look");
    quickViewElement.classList.add("u-hidden");
  }
}