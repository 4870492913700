import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shiftCheckbox", "shiftDays"];

  connect() {}

  showQuickLook(event) {
    event.preventDefault();
    console.error('Working now')
    const premiumInstructorId = this.data.get("premiumInstructorIdValue");
    const id = event.currentTarget.dataset.id;
    const url = `/schools/setup/shifts/assignments/flexible-quick-look?premium_instructor_id=${premiumInstructorId}&id=${
      id || ""
    }`;

    const f = async() => {
      const response = await this.performFetch(url, "GET");
  
      if (response.ok) {
        const quickViewElement = document.querySelector(
          "#school-shifts-quick-look"
        );
        quickViewElement.classList.remove("u-hidden");
  
        const responseBody = await response.text();
        console.log(responseBody);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(responseBody, "text/html");
        const template = xmlDoc.querySelector("template");
        const turboFrameElement = quickViewElement;
  
        if (turboFrameElement && template) {
          turboFrameElement.innerHTML = "";
          turboFrameElement.appendChild(template.content.cloneNode(true));
        }
      } else {
        console.log("Error", response);
      }
    };

    f();
  }

  async performFetch(url, method, body = null) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const headers = {
      "X-CSRF-Token": csrfToken,
      Accept: "text/vnd.turbo-stream.html",
    };

    const options = {
      method: method,
      headers: headers,
    };

    if (body) {
      options.body = body;
    }

    try {
      return await fetch(url, options);
    } catch (error) {
      console.error("Error performing fetch: ", error);
      return {
        error: "Network error, please check your connection and try again.",
      };
    }
  }
}
