import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["submit"]
    static values = {
        requiredTypes: Array
    }
    connect() {
        console.log('...')
        this.files = new Map()
        this.updateSubmitButton()
    }

    handleFileValidation(event) {
        const { type, file } = event.detail
        this.files.set(type, file)
        this.updateSubmitButton()
    }

    handleFileRemoval(event) {
        const { type } = event.detail
        this.files.delete(type)
        this.updateSubmitButton()
    }

    updateSubmitButton() {
        const allFilesPresent = this.requiredTypesValue.every(type =>
            this.files.has(type)
        )

        this.submitTarget.disabled = !allFilesPresent
        this.submitTarget.classList.toggle('is-disabled', !allFilesPresent)
    }

    submitFiles(event) {

        //disable the submit button
        this.submitTarget.disabled = true

        event.preventDefault()

        const formData = new FormData()
        this.files.forEach((file, type) => {
            formData.append(`files[${type}]`, file)
        })

        fetch(this.submitTarget.dataset.submitUrl, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
            .then(html => {
                window.location.href = '/data-importer'

            })
            .catch(error => {
                // re-enable the submit button
                this.submitTarget.disabled = false
                console.error('Upload failed:', error)
            })
    }
}