import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["dropZone", "input", "error", "preview", "fileList",
        "notUploaded", "validating", "valid", "invalid"]

    static values = {
        type: String,
        requiredHeaders: Array
    }

    connect() {
        this.showStatus('notUploaded')
    }

    dragOver(event) {
        event.preventDefault()
        event.currentTarget.classList.add('is-dragover')
    }

    dragLeave(event) {
        event.preventDefault()
        event.currentTarget.classList.remove('is-dragover')
    }

    drop(event) {
        event.preventDefault()
        event.currentTarget.classList.remove('is-dragover')

        const files = Array.from(event.dataTransfer.files)
        if (files.length > 0) {
            this.handleFileSelection(files[0])
        }
    }

    browse() {
        this.inputTarget.click()
    }

    handleFiles(event) {
        const file = event.currentTarget.files[0]
        if (file) {
            this.handleFileSelection(file)
        }
    }

    async handleFileSelection(file) {
        if (!file.name.match(/\.(csv)$/i)) {
            this.showError(`${file.name} is not a CSV file`)
            return
        }

        this.showStatus('validating')

        try {
            await this.validateFileHeaders(file)
            this.hideError()
            this.updatePreview(file)
            this.showStatus('valid')
            this.dispatch('validated', {
                detail: {
                    type: this.typeValue,
                    file: file
                }
            })
        } catch (error) {
            this.showError(error)
            this.showStatus('invalid')
        }
    }


// Add this method if it doesn't exist already
    hideError() {
        this.errorTarget.textContent = ''
        this.errorTarget.classList.add('u-hidden')
    }

    validateFileHeaders(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.onload = (e) => {
                try {
                    const content = e.target.result
                    const lines = content.split('\n')
                    if (lines.length === 0) {
                        reject("File appears to be empty")
                        return
                    }

                    const headers = lines[0].split(',').map(h => h.trim().replace(/^"|"$/g, ''))
                    const missingHeaders = this.requiredHeadersValue.filter(header =>
                        !headers.includes(header)
                    )

                    if (missingHeaders.length > 0) {
                        reject(`Missing required headers: ${missingHeaders.join(', ')}`)
                        return
                    }

                    resolve({ valid: true, headers })
                } catch (error) {
                    reject(`Error processing file: ${error.message}`)
                }
            }

            reader.readAsText(file)
        })
    }

    showStatus(status) {
        const statuses = ['notUploaded', 'validating', 'valid', 'invalid']
        statuses.forEach(s => {
            this[`${s}Target`].classList.toggle('u-hidden', s !== status)
        })
    }

    updatePreview(file) {
        this.fileListTarget.innerHTML = `
            <div class="Alert Alert--success">
                <div class="Alert-content">
                    <strong>${file.name}</strong> has been validated and is ready to import
                </div>
                <button type="button" 
                        class="Alert-close"
                        data-action="click->driveato-dss-file-importer#removeFile">
                    ×
                </button>
            </div>
        `
    }

    removeFile() {
        this.showStatus('notUploaded')
        this.fileListTarget.innerHTML = ''
        this.dispatch('removed', {
            detail: {
                type: this.typeValue
            }
        })
    }

    showError(message) {
        this.errorTarget.textContent = message
        this.errorTarget.classList.remove('u-hidden')
    }
}