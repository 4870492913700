import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["canvas"]
    static values = {
        data: Array
    }

    connect() {
        // Add a console.log to debug the data
        console.log("Controller connected", this.dataValue)
        this.initializeChart()
    }

    initializeChart() {
        const ctx = this.canvasTarget.getContext("2d")
        const data = this.dataValue

        // Debug the data
        console.log("Processing data:", data)

        const labels = []
        const totalSignups = []
        const activeInstructors = []

        for (const item of data) {
            labels.push(`${item.month}/${item.year}`)
            totalSignups.push(item.total_signups)
            activeInstructors.push(item.active_instructors)
        }

        // Debug the processed arrays
        console.log("Labels:", labels)
        console.log("Total Signups:", totalSignups)
        console.log("Active Instructors:", activeInstructors)

        // Reverse arrays to display newest months on right
        labels.reverse()
        totalSignups.reverse()
        activeInstructors.reverse()

        new Chart(ctx, {
            type: "line",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Total Signups",
                        data: totalSignups,
                        borderColor: "rgba(75, 192, 192, 1)",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        fill: true
                    },
                    {
                        label: "Active Instructors",
                        data: activeInstructors,
                        borderColor: "rgba(255, 99, 132, 1)",
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        fill: true
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
        })
    }
}