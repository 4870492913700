import { Controller } from "@hotwired/stimulus";
import * as Luxon from "luxon";

export default class extends Controller {
  static targets = ["button"];

  inputSelector = "";
  selectedIds = [];
  hasBeenInteractedWith = false;

  connect() {
    setTimeout(() => {
      this.inputSelector = this.data.get("inputSelector");
      this.selectedIds = JSON.parse(this.data.get("selectedIds"));
      console.log(this.selectedIds);
      this.redraw();
    }, 0);
  }

  toggle(event) {
    const button = event.currentTarget;
    const optionId = JSON.parse(button.dataset.optionId);

    if (
      this.selectedIds.some((id) => {
        console.log("Selected Id");
        console.log(id);
        console.log("Option Id");
        console.log(optionId);
        return JSON.stringify(id) === JSON.stringify(optionId);
      })
    ) {
      this.selectedIds = this.selectedIds.filter(
        (id) => JSON.stringify(id) !== JSON.stringify(optionId)
      );
    } else {
      this.selectedIds.push(optionId);
    }

    this.hasBeenInteractedWith = true;
    this.redraw();
  }

  redraw() {
    // Go through all the buttons
    this.buttonTargets.forEach((button) => {
      const buttonId = JSON.parse(button.dataset.optionId);

      // If the button is selected, add the selected class
      if (
        this.selectedIds.some(
          (id) => JSON.stringify(id) === JSON.stringify(buttonId)
        )
      ) {
        button.classList.remove("Button--alwaysoutline");
      } else {
        button.classList.add("Button--alwaysoutline");
      }
    });

    const input = document.querySelector(this.inputSelector);
    if (input) {
      input.value = JSON.stringify(this.selectedIds);
      if (this.hasBeenInteractedWith) {
        input.dispatchEvent(new Event("input", { bubbles: true }));
        input.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }
  }
}
