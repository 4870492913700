import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "slotTab",
    "dateTimeTab",

    "slotTabContent",
    "dateTimeTabContent",
    "tabs",
    "timeEditButton",
  ];

  areInstructorsExpanded = false;
  areInstructorsOutOfZoneExpanded = false;
  areVehiclesExpanded = false;

  instructorSearchTerm = "";
  vehicleSearchTerm = "";

  activeTab = "";

  selectedInstructorId = this.data.get("instructorId");
  selectedVehicleId = this.data.get("vehicleId");

  conflictCallId = null;
  conflictMap = {
    vehicles: {},
    instructors: {},
  };

  connect() {
    setTimeout(() => {
      this.activeTab = "date_time";
      this.setDisplayProperties();
      this.findConflicts();
      this.refreshSlots();
    });
  }

  selectInstructorV2(event) {
    const dispatchItem = event.detail.dispatchItem;
    const instructorId = dispatchItem.id;
    const vehicleId = dispatchItem.vehicleId;

    this.selectedInstructorId = instructorId;

    this.dispatch("selectVehicle", {
      detail: {
        id: vehicleId,
      },
    });
    this.findConflicts();
    this.refreshSlots();
  }

  selectVehicleV2(event) {
    const dispatchItem = event.detail.dispatchItem;
    const vehicleId = dispatchItem.id;

    this.selectedVehicleId = vehicleId;

    this.findConflicts();
    this.refreshSlots();
  }

  selectInstructor(event) {
    console.log("selectInstructor");
    const id = event.currentTarget.dataset.instructorId;
    const name = event.currentTarget.dataset.instructorName;
    const image = event.currentTarget.getElementsByTagName("img")[0].src;

    const vehicleId = event.currentTarget.dataset.instructorVehicleId;

    // this.currentInstructorWrapperTarget.dataset.instructorId = id;
    this.currentInstructorNameTarget.innerHTML = name;
    this.currentInstructorImageTarget.src = image;

    this.closeSelection();

    const vehicleElement = this.element.querySelector(
      `[data-vehicle-id="${vehicleId}"].vehicle-for-selection`
    );

    if (vehicleElement) {
      this.selectVehicle({ currentTarget: vehicleElement });
    } else {
      this.findConflicts();
      this.refreshSlots();
    }
  }

  selectVehicle(event) {
    console.log("selectVehicle");
    const id = event.currentTarget.dataset.vehicleId;
    const name = event.currentTarget.dataset.vehicleName;

    // this.currentVehicleWrapperTarget.dataset.vehicleId = id;
    this.currentVehicleNameTarget.innerHTML = name;

    this.closeVehicleSelection();
    this.findConflicts();
    this.refreshSlots();
  }

  filterInstructors(event) {
    console.log("filterInstructors");
    this.instructorSearchTerm = event.currentTarget.value;
    console.log(this.instructorSearchTerm);
    this.setDisplayProperties();
  }

  filterVehicles(event) {
    console.log("filterVehicles");
    this.vehicleSearchTerm = event.currentTarget.value;
    console.log(this.vehicleSearchTerm);
    this.setDisplayProperties();
  }

  chosenTimeChanged(event) {
    console.log("chosenTimeChanged");
    this.findConflicts();
  }

  closeSelection() {
    console.log("closeSelection");
    this.areInstructorsExpanded = false;
    this.setDisplayProperties();
  }

  toggleSelection() {
    console.log("toggleSelection");
    this.areInstructorsExpanded = !this.areInstructorsExpanded;
    this.setDisplayProperties();
  }

  closeVehicleSelection() {
    console.log("closeVehicleSelection");
    this.areVehiclesExpanded = false;
    this.setDisplayProperties();
  }

  toggleVehicleSelection() {
    console.log("toggleVehicleSelection");
    this.areVehiclesExpanded = !this.areVehiclesExpanded;
    this.setDisplayProperties();
  }

  toggleOutOfZoneSelection() {
    console.log("toggleOutOfZoneSelection");
    this.areInstructorsOutOfZoneExpanded =
      !this.areInstructorsOutOfZoneExpanded;
    this.setDisplayProperties();
  }

  activateSlotTab() {
    console.log("activateSlotTab");
    this.activeTab = "slot";
    this.setDisplayProperties();
  }

  activateDateTimeTab() {
    console.log("activateDateTimeTab");
    this.activeTab = "date_time";
    this.setDisplayProperties();
  }

  /**
   * This method sets the html and style properties
   * based on the properties on this class
   */
  setDisplayProperties() {
    const showTabs = !!this.activeTab;
    if (this.tabsTarget) {
      this.tabsTarget.style.display = showTabs ? "block" : "none";
    }

    if (this.timeEditButtonTarget) {
      this.timeEditButtonTarget.style.display = showTabs ? "none" : "block";
    }

    const highlightSlotTab = this.activeTab === "slot";
    this.slotTabTarget.classList.toggle("is-active", highlightSlotTab);
    // this.slotTabContentTarget.style.display = highlightSlotTab ? "block" : "none";
    // Want the above line but collapse instead of none so it is still loaded
    this.slotTabContentTarget.style.height = highlightSlotTab ? "auto" : "0px";
    this.slotTabContentTarget.style.overflow = highlightSlotTab
      ? "visible"
      : "hidden";

    const highlightDateTimeTab = this.activeTab === "date_time";
    this.dateTimeTabTarget.classList.toggle("is-active", highlightDateTimeTab);
    this.dateTimeTabContentTarget.style.display = highlightDateTimeTab
      ? "block"
      : "none";

    const showVehicleSearch = this.areVehiclesExpanded;

    const rotateDropdown = this.areInstructorsExpanded;

    const rotateVehicleDropdown = this.areVehiclesExpanded;

    const showVehicles = this.areVehiclesExpanded;
    const vehicles = document.getElementsByClassName("vehicle");
    for (let i = 0; i < vehicles.length; i++) {
      const element = vehicles[i];
      const vehicleName = element.dataset.vehicleName;
      const showThisVehicle = vehicleName
        .toLowerCase()
        .includes(this.vehicleSearchTerm.toLowerCase());
      element.style.height = showVehicles && showThisVehicle ? "50px" : "0px";
    }
  }

  async refreshSlots() {
    // Here we edit the parameters on the week view controller to trigger the refresh for slots
    this.slotTabContentTarget.dataset.weekViewPostParameterInstructorId =
      this.selectedInstructorId;
    this.slotTabContentTarget.dataset.weekViewPostParameterVehicleId =
      this.selectedVehicleId;
  }

  // Go to the API and find conflicts
  // then display on the instructors and vehicles where there are conflicts
  async findConflicts() {
    const randomId = Math.random();
    this.conflictCallId = randomId;
    this.hideAllBadges();
    this.showAllLoadingBadges();

    const instructorId = this.selectedInstructorId;
    const vehicleId = this.selectedVehicleId;
    const isoTime = document.getElementById("picked_slot_iso_time").value; // TODO: Make this id dynamic passed in as a controller argument
    const durationInMinutes = document.getElementById(
      "picked_slot_duration"
    ).value; // TODO: Make this id dynamic passed in as a controller argument

    // Get the school token from the stimulus controller passed in
    const schoolToken = this.data.get("schoolToken");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const url = `/schools/${schoolToken}/find-conflicts-at-time`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        instructor_id: instructorId,
        vehicle_id: vehicleId,
        iso_start_time: isoTime,
        duration: durationInMinutes,
        current_lesson_id: this.data.get("lessonId"),
      }),
    });

    if (this.conflictCallId !== randomId) {
      console.log("Conflict call was cancelled");
      return;
    }

    if (response.ok) {
      const responseBody = await response.text();
      const asJson = JSON.parse(responseBody);
      this.conflictMap = asJson;
      this.setConflictBadgesOnCards();
      console.log(asJson);
      return asJson;
    } else {
      console.log(response);
      return { errorMessage: "Something went wrong" };
    }
  }

  // Uses the conflicts map (from the API) to display which cards have conflicts
  setConflictBadgesOnCards() {
    this.hideAllBadges();

    // Iterate through instructors
    Object.keys(this.conflictMap.instructors).forEach((instructorId) => {
      const isCurrent = this.conflictMap.instructors[instructorId]["current"];
      const hasConflict =
        !isCurrent && this.conflictMap.instructors[instructorId]["conflict"];

      if (isCurrent) {
        this.dispatch("addBadge", {
          detail: {
            id: instructorId,
            badgeText: "Current",
            badgeClasses: [
              "Badge",
              "Badge--grey",
              "u-fontBody",
              "u-normal",
              "u-alignMiddle",
              "ml1",
              `instructor-${instructorId}-current-badge`,
            ],
          },
        });
      }

      if (hasConflict) {
        this.dispatch("addBadge", {
          detail: {
            id: instructorId,
            badgeText: "Conflict",
            badgeClasses: [
              "Badge",
              "Badge--red",
              "u-fontBody",
              "u-normal",
              "u-alignMiddle",
              "ml1",
              `instructor-${instructorId}-conflict-badge`,
            ],
          },
        });
      }
    });

    console.log(this.conflictMap.vehicles);

    // Iterate through vehicles
    Object.keys(this.conflictMap.vehicles).forEach((vehicleId) => {
      const isCurrent = this.conflictMap.vehicles[vehicleId]["current"];
      const hasConflict =
        !isCurrent && this.conflictMap.vehicles[vehicleId]["conflict"];

      if (isCurrent) {
        this.dispatch("addVehicleBadge", {
          detail: {
            id: vehicleId,
            badgeText: "Current",
            badgeClasses: [
              "Badge",
              "Badge--grey",
              "u-fontBody",
              "u-normal",
              "u-alignMiddle",
              "ml1",
              `instructor-${vehicleId}-current-badge`,
            ],
          },
        });
      }

      if (hasConflict) {
        this.dispatch("addVehicleBadge", {
          detail: {
            id: vehicleId,
            badgeText: "Conflict",
            badgeClasses: [
              "Badge",
              "Badge--red",
              "u-fontBody",
              "u-normal",
              "u-alignMiddle",
              "ml1",
              `instructor-${vehicleId}-conflict-badge`,
            ],
          },
        });
      }
    });
  }

  hideAllBadges() {
    this.dispatch("addBadge", {
      detail: {
        badgeText: "Loading...",
        badgeClasses: [
          "Badge",
          "Badge--grey",
          "Badge--loading",
          "u-fontBody",
          "u-normal",
          "u-alignMiddle",
          "ml1",
          "u-hidden",
        ],
      },
    });

    this.dispatch("addVehicleBadge", {
      detail: {
        badgeText: "Loading...",
        badgeClasses: [
          "Badge",
          "Badge--grey",
          "Badge--loading",
          "u-fontBody",
          "u-normal",
          "u-alignMiddle",
          "ml1",
          "u-hidden",
        ],
      },
    });
  }

  showAllLoadingBadges() {
    this.dispatch("addBadge", {
      detail: {
        badgeText: "Loading...",
        badgeClasses: [
          "Badge",
          "Badge--grey",
          "Badge--loading",
          "u-fontBody",
          "u-normal",
          "u-alignMiddle",
          "ml1",
        ],
      },
    });

    this.dispatch("addVehicleBadge", {
      detail: {
        badgeText: "Loading...",
        badgeClasses: [
          "Badge",
          "Badge--grey",
          "Badge--loading",
          "u-fontBody",
          "u-normal",
          "u-alignMiddle",
          "ml1",
        ],
      },
    });
  }

  async confirmSave() {
    console.log("confirmSave");

    const instructorId = this.selectedInstructorId;
    const vehicleId = this.selectedVehicleId;
    const isoTime = document.getElementById("picked_slot_iso_time").value;
    const durationInMinutes = document.getElementById(
      "lesson_new_duration_in_minutes"
    ).value;
    const schoolToken = this.data.get("schoolToken");
    const lessonId = this.data.get("lessonId");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    let button = event.currentTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");
    button.disabled = true;

    const url = `/schools/${schoolToken}/quick-look-edit-lesson`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        instructor_id: instructorId,
        vehicle_id: vehicleId,
        iso_start_time: isoTime,
        lesson_id: lessonId,
        duration_in_minutes: durationInMinutes,
      }),
    });

    if (response.ok) {
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      this.element.disabled = false;

      // const responseBody = await response.text();
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
      return true;
    } else {
      let spinner = button.querySelector(".Spinner");
      spinner.classList.remove("u-hidden");
      spinner.classList.add("u-inlineBlock");
      button.disabled = true;

      const responseBody = await response.text();
      const asJson = JSON.parse(responseBody);
      const errorMessage =
        asJson["error"] ||
        "Something went wrong, please check the details and try again";
      alert(errorMessage);
      return false;
    }
  }
}
